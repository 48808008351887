<div class='admin'>
    <app-beheer-nav></app-beheer-nav>
    <!-- Only show once statistics is loaded A-Sync.-->

    <div class='adminpanel' >
      <h2>Huidige luisteraars: <span>{{currentListeners}}</span></h2>
      <ng-container *ngIf="competition">
        <h4>Luisteraars MFM: <span>{{competition.MFM}}</span></h4>
        <h4>Luisteraars Favoriet: <span>{{competition.FAVORIET}}</span></h4>
        <h4>LFM Positie in Top 100: <span>{{competition.LFMPOS}}</span></h4>
      </ng-container>
      <h3>(Ververst elke 30 seconden)</h3>
      <br>
      <h2>Gemiddeld aantal luisteraars per uur</h2>
      <h3>(gemeten over de afgelopen 28 dagen)</h3>
      <div id="averageListenersPerHourLastMonth_chart_div" style="position: relative; width: 100%; height: 500px;">
        <p>Loading "Gemiddeld aantal luisteraars per uur"</p>
      </div>
      <br>
      <h2>Luisteraars per minuut </h2>
      <h3>(gemeten over de laatste {{listenersPerMinDays}} dagen)</h3>

      <div id="listenersOverLastXDays_dashboard_div" style="position: relative; width: 100%; min-height: 500px;">
        <!--Divs that will hold each control and chart-->
        <div id="listenersOverLastXDays_chart_div">
          <p>Loading "Luisteraars per min over the laatsete {{listenersPerMinDays}} daagen"</p>
        </div>
        <div id="listenersOverLastXDays_filter_div"></div>
      </div>
      <br>
      <br>
      <!--
      <div id="avgListenerCalendar_div" style="position: relative; width: 100%; height: 150px;" class="contentCentered">
        <p style="font-size: large;">Loading gemiddeld luisteraars per dag calendar</p>
      </div>
      <br>
      <div id="minListenerCalendar_div" style="position: relative; width: 100%; height: 150px;" class="contentCentered">
        <p style="font-size: large; ">Loading min luisteraars per dag calendar</p>
      </div>
    -->
      <br>
      <div id="maxListenerCalendar_div" style="position: relative; width: 100%; height: 150px;" class="contentCentered">
        <p style="font-size: large;">Loading max luisteraars per dag calendar</p>
      </div>
    </div>
</div>
