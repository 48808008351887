import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { ProgrammasComponent } from './pages/programmas/programmas.component';
import { MessagesComponent } from './messages/messages.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ListeneverywhereComponent } from './listeneverywhere/listeneverywhere.component';
import { StaffComponent } from './staff/staff.component';
import { StaffMemberComponent } from './staffmember/staff-member.component';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import { sanitizeHtmlPipe } from './toolkit/sanitize-html.pipe';
import { Nl2pbrPipe } from './toolkit/nl2pbr.pipe';
import { KeysPipe } from './toolkit/KeysPipe';
import { ShowsComponent } from './shows/shows.component';
import { ShowComponent } from './show/show.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { ProgrammingComponent } from './programming/programming.component';
import { JsogService } from 'jsog-typescript';
import { LiveComponent } from './live/live.component';
import { LoginComponent } from './login/login.component';
import { BeheerComponent } from './beheer/beheer.component';
import { UserService } from './user.service';
import { BeheerNavComponent } from './beheer-nav/beheer-nav.component';
import { LogoutComponent } from './logout/logout.component';
import { AdmProgrammingComponent } from './admin/adm-programming/adm-programming.component';
import { AdmBlocksComponent } from './admin/adm-blocks/adm-blocks.component';
import { AdmStaffComponent } from './admin/adm-staff/adm-staff.component';
import { AdmProfileComponent } from './admin/adm-profile/adm-profile.component';
import { AdmShowsComponent } from './admin/adm-shows/adm-shows.component';
import { AdmUsersComponent } from './admin/adm-users/adm-users.component';
import { AdmStaffmemberComponent } from './admin/adm-staffmember/adm-staffmember.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AdmBlockComponent } from './admin/adm-block/adm-block.component';
import { AdmShowComponent } from './admin/adm-show/adm-show.component';
import { ArticleComponent } from './admin/article/article.component';
import { AdmUserComponent } from './admin/adm-user/adm-user.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { ProgramminghourComponent } from './admin/programminghour/programminghour.component';
import { environment } from '../environments/environment';
import { StatisticsComponent } from './admin/statistics/statistics.component';
import { StatisticComponent } from './admin/statistic/statistic.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { HttpErrorInterceptor } from './HttpErrorInterceptor';
import { AdverterenComponent } from './pages/adverteren/adverteren.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MedewerkersComponent } from './pages/medewerkers/medewerkers.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HowToListenComponent } from './how-to-listen/how-to-listen.component';
import { NewsComponent } from './news/news.component';
import { VerzoekjeComponent } from './verzoekje/verzoekje.component';



registerLocaleData(localeNl, 'nl', localeNlExtra);

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    ProgrammasComponent,
    MessagesComponent,
    DashboardComponent,
    HeaderComponent,
    WelcomeComponent,
    ListeneverywhereComponent,
    StaffComponent,
    StaffMemberComponent,
    sanitizeHtmlPipe,
    Nl2pbrPipe,
    KeysPipe,
    ShowsComponent,
    ShowComponent,
    ProgrammingComponent,
    LiveComponent,
    LoginComponent,
    BeheerComponent,
    BeheerNavComponent,
    LogoutComponent,
    AdmProgrammingComponent,
    AdmBlocksComponent,
    AdmStaffComponent,
    AdmProfileComponent,
    AdmShowsComponent,
    AdmUsersComponent,
    AdmStaffmemberComponent,
    AdmBlockComponent,
    AdmShowComponent,
    ArticleComponent,
    AdmUserComponent,
    ChangepasswordComponent,
    ProgramminghourComponent,
    StatisticsComponent,
    StatisticComponent,
    AdverterenComponent,
    ContactComponent,
    MedewerkersComponent,
    HomepageComponent,
    HowToListenComponent,
    NewsComponent,
    VerzoekjeComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    SlideshowModule,
    GoogleChartsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: environment.whitelistedDomains,
        disallowedRoutes: environment.blacklistedRoutes
      }
    }),
  ],
  providers: [
    JsogService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
