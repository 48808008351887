import { Component, OnInit } from '@angular/core';
import { AgendaItem } from '../data/agendaitem';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit {
  agendaItems: AgendaItem[] = [];

  date = new Date();

  switchTime = new Date('01/08/2022 5:00:00 PM');
  switchTime2 = new Date('01/10/2022 1:00:00 PM');

  constructor() { }

  ngOnInit() : void {
    this.getAgendaItems();
  }

  getAgendaItems(): void {

  }
}
