<div class='welcome'>
  <div class='shows'>

    <ng-container>

      <h1>
        Kerst bij L-FM.nl
      </h1>
      <p>
        Het is Kersttijd en L-FM.nl draait ze weer, elk uur een paar mooie oude Kerstplaatje. Lekker non-stop genieten dus met heerlijke oldies met zo af en toe een Kerstplaatje.<br>
        We hebben onze platencollectie intussen flink uitgebreid met nieuwe nummers. Luister en geniet van de mooiste oldies bij L-FM.nl.<br>
        Elke zondag is het weer ouderwets genieten; van 11 tot 12 uur: een uurtje non-stop Duitse muziek. Daarna vanaf 12 uur heerlijk 2 uurtjes Mari’s Feestje. Lekker non-stop genieten van de plaatjes die Mari ooit draaide op de radio. Dit programma draait ook weer op de woensdagavond van 20.00 tot 22.00 uur.<br>
        Op zaterdagmiddag tussen 12 en 2 draaien we mooie Golden Oldies. Nieuwe oude hits die je niet elke dag hoort.<br>
        Voor meer info even kijken op de programmering van L-FM.nl, deze kun je nu weer bovenin de navigatiebalk vinden.<br>

      </p>

      <h1>
        Nieuwe oldies!
      </h1>
      <p>
        Omdat de zomervakantie weer voorbij is, en we nu geen live uitzendingen meer draaien, wil dat niet zeggen dat LFM stil heeft gezeten!<br>
        Wij hebben hard gewerkt aan een nieuwe verzameling van evergreens: Oldies die we nog niet eerder in onze playout hadden zitten, maar ook 'nieuwe' oldies.<br>
        Ongeveer 10% extra nummers zijn toegevoegd, dus ook de oude bekende klassiekers van L-FM blijf je ook gewoon nog horen.<br>
        Dus een nieuwe gezellige mix van 60s, 70s, 80s en soms zelfs al de 90s, knallen straks weer uit je radio!

      </p>

      <h1>
        Een nieuw kleurtje!
      </h1>
      <p>
        We hebben het kleurenschema van onze website wat aangepast. Het is een nieuw kleurtje, want het is voor ons een nieuw begin. Ook is het een ander kleurtje, want de zender is natuurlijk ook niet meer hetzelfde.<br>
        <br>Waarom dan groen?<br>
        We hebben gekozen voor de kleur dennengroen. Een denneboom is namelijk een boom die altijd groen is. Een zogenaamde 'evergreen'. En dat is nu net wat wij hier draaien: evergreens! Mooi toch?<br>
        <br>
        Ook hebben we de volledige studio volledig gedigitaliseerd. Hierdoor verbruikt de studio 99% minder energie. En dat is goed voor het milieu, en daarom dus ook: groen!
      </p>

      <h1>
        L-FM is gestopt als lokale radio voor de gemeente Landerd
      </h1>
      <p>
        L-FM is gestopt met haar live uitzendingen voor de gemeente Landerd. Wegens de fusie met gemeente Uden, is er maar plek voor één gemeentezender.<br>
        Daarmee is de FM frequentie te vervallen en is L-FM helaas genoodzaakt te stoppen.<br><br> De webstream blijft echter nog te beluisteren via deze website.<br>
        De website draait niet meer onder de vlag van L-FM, maar wordt beheerd door de voormalig technisch team van L-FM.<br>
        Hierdoor zorgen wij er voor dat het hart, de geest en het nalatenschap van LFM door blijft gaan. Het format en muziek blijft dus bewaard. <br>
      </p>

    </ng-container>

    <ng-container *ngIf="false">
      <h1>
        ...maar online draaien wij Non-Stop door!!!
      </h1>
      Wil je alsnog naar de prachtige muziek collectie van LFM blijven luisteren? Dat kan!<br>
      Enkele vrijwilligers, DJ's en techneuten hebben besloten op eigen initiatief de LFM website en haar muziek voor het publiek te behouden.<br>
      Via onze website en webstream wordt het bekende format van LFM gewoon door gedraaid.<br>
      Je kunt daar blijven genieten van de hits, oldies en evergreens die u gewend bent, en wij zullen deze collectie blijven onderhouden, zodat er straks ook 'nieuwe' oldies bij komen.<br>
      Non-stop en zonder reclame onderbrekingen. Het hart van L-FM draait door op L-FM.nl<br>
      <br>
      Non-stop is en blijft... non-stop!<br>
      <br>



    </ng-container>



  </div>
</div>

