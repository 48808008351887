import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserService} from '../../user.service';

import {AgendaItem} from '../../data/agendaitem';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
  @Input() agendaItem: AgendaItem;
  isLoggedIn: boolean;
  isAdmin: boolean;
  agendaDate: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getAgendaItem();
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
  }

  getAgendaItem(): void {
    const id = +this.route.snapshot.paramMap.get('id');
  }


  save(): void {
    this.agendaItem.expires = new Date(this.agendaItem.expires);
    this.agendaItem.expires.setHours(23, 59, 59, 0);
  }

  delete(): void {

  }

  goBack(): void {
    this.location.back();
  }

  goTo(url): void {
    this.router.navigateByUrl(url);
  }

  goBackTwice(): void {
    this.location.back();
    this.location.back();
  }

}
