<div class='ruis'>
<div class='mainsitecontainer'>
  <app-header></app-header>
  <nav>
    <a routerLink="/home">Home</a> |
    <a routerLink="/hoe">Hoe te luisteren</a> |
    <a routerLink="/shows" *ngIf="date>switchTime">Programmering</a> |
    <a routerLink="/nieuws">Nieuws</a> |
    <a routerLink="/verzoekje">Verzoekje</a> |
    <a href="https://www.facebook.com/lfm.nl/"><i class="fab fa-facebook"></i></a><!-- |
    <a routerLink="/shows" *ngIf="date<switchTime">Programmas</a>

    <a routerLink="/staff" *ngIf="date<switchTime">Medewerkers</a>
    <a routerLink="/medewerkers" *ngIf="date>switchTime">Medewerkers</a> |
    <a routerLink="/adverteren">Adverteren</a> |
    <a routerLink="/contact">Contact</a>-->
  </nav>
  <nav class="tempNav"></nav>
  <div class='main'>
  <router-outlet class='router'></router-outlet>

  <!--<app-messages></app-messages>-->
  </div>
  <footer>
    <div class='copyright'>&copy;{{date| date:"yyyy"}} L-FM.nl Alle rechten voorbehouden</div>
    <div class='cookies'>L-FM.nl maakt geen gebruik van cookies</div>
  </footer>
</div>
</div>
