<div class='welcome'>
  <table cellspacing=0 cellpadding=0>
    <tr>
      <td class='twothird'><app-welcome></app-welcome></td>

    </tr>
  </table>
  <div class='shows'>

    <h1>
      Nieuwe oldies!
    </h1>
    <p>
      Omdat de zomervakantie weer voorbij is, en we nu geen live uitzendingen meer draaien, wil dat niet zeggen dat LFM stil heeft gezeten!<br>
      Wij hebben hard gewerkt aan een nieuwe verzameling van evergreens: Oldies die we nog niet eerder in onze playout hadden zitten, maar ook 'nieuwe' oldies.<br>
      Ongeveer 10% extra nummers zijn toegevoegd, dus ook de oude bekende klassiekers van L-FM blijf je ook gewoon nog horen.<br>
      Dus een nieuwe gezellige mix van 60s, 70s, 80s en soms zelfs al de 90s, knallen straks weer uit je radio!

    </p>




    <h1>
        Een nieuw kleurtje!
      </h1>
      <p>
        We hebben het kleurenschema van onze website wat aangepast. Het is een nieuw kleurtje, want het is voor ons een nieuw begin. Ook is het een ander kleurtje, want de zender is natuurlijk ook niet meer hetzelfde.<br>
        <br>Waarom dan groen?<br>
        We hebben gekozen voor de kleur dennengroen. Een denneboom is namelijk een boom die altijd groen is. Een zogenaamde 'evergreen'. En dat is nu net wat wij hier draaien: evergreens! Mooi toch?<br>
        <br>
        Ook hebben we de volledige studio volledig gedigitaliseerd. Hierdoor verbruikt de studio 99% minder energie. En dat is goed voor het milieu, en daarom dus ook: groen!
      </p>



  </div>
</div>

